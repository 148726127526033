import React from 'react'
import { Text, Flex, Image, Button, Box } from 'rebass'
import chromeIcon from './chrome-icon.png'
import safariIcon from './safari-icon.png'
import styles from './styles'
import { Overlay } from 'uneeq-react-ui'

import { isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import MayaCloseButton from '../MayaCloseButton/MayaCloseButton'
import { MayaOverlay } from '../MayaOverlay'

interface MayaUnsupportedBrowserProps {
  close: () => void
  continueChatMode: () => void
}

const MayaUnsupportedBrowser: React.FC<MayaUnsupportedBrowserProps> = ({
  close,
  continueChatMode
}) => {
  const { t } = useTranslation()
  return (
    <MayaOverlay sx={{ backgroundColor: '#515151c2', zIndex: 99 }}>
      <Flex sx={styles.container}>
        <MayaCloseButton
          onClick={close}
          customStyles={{
            position: 'absolute',
            top: 2,
            right: 2,
            backgroundColor: 'transparent'
          }}
        />
        <Text sx={styles.heading}>{t('UnsupportedBrowser.heading')}</Text>
        <Text sx={styles.paragraph}>{t('UnsupportedBrowser.paragraph1')}</Text>
        <ul style={styles.requirementList}>
          <li style={styles.requirement}>
            <Text> {t('UnsupportedBrowser.windowsRequirement')}</Text>
          </li>
          <li style={styles.requirement}>
            <Text> {t('UnsupportedBrowser.macRequirement')}</Text>
          </li>
          <li style={styles.requirement}>
            <Text> {t('UnsupportedBrowser.iosRequirement')}</Text>
          </li>
          <li style={styles.requirement}>
            <Text> {t('UnsupportedBrowser.androidRequirement')}</Text>
          </li>
        </ul>
        {/* <Text sx={styles.paragraph}>{t('UnsupportedBrowser.paragraph2')}</Text> */}
        <Flex sx={styles.browserIcons}>
          {!isIOS && <Image src={chromeIcon} alt="Chrome" mr={2} />}
          <Image src={safariIcon} alt="Safari" />
        </Flex>
        <Flex sx={styles.buttons}>
          {!isIOS && (
            <Button
              variant="primary"
              as="a"
              href="https://www.google.com/chrome/"
            >
              {t('UnsupportedBrowser.getChrome')}
            </Button>
          )}
          <Button variant="whiteInverted" onClick={continueChatMode}>
            {t('UnsupportedBrowser.chatMode')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default MayaUnsupportedBrowser
