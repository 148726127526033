import React, { useContext } from 'react'
// @ts-ignore
import { Button, Flex, Heading, Text } from 'rebass'
import { Overlay } from 'uneeq-react-ui'
import styles from './styles'
import {
  trackHandler,
  useUneeqState,
  UneeqContext,
  useDisableBodyOverflow
} from 'uneeq-react-core'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import { MayaOverlay } from '../MayaOverlay'

interface MayaConfirmLeaveProps {
  onConfirm: () => any
}

const MayaConfirmLeave = React.memo(({ onConfirm }: MayaConfirmLeaveProps) => {
  const { confirmLeaveOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { widgetMode } = useWidgetContext()

  useDisableBodyOverflow(confirmLeaveOpen)

  const closeConfirmLeave = () => dispatch({ type: 'mayaCloseConfirmLeave' })
  const handleConfirm = () => {
    onConfirm()
    closeConfirmLeave()
  }

  if (!confirmLeaveOpen) return null

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Heading sx={styles.heading}>Are you sure you want to leave?</Heading>
        <Text sx={styles.text}>You will lose the progress in this session</Text>
        <Flex sx={styles.buttonsContainer}>
          <Button
            variant="whiteInverted"
            onClick={trackHandler(handleConfirm, 'exit-session-btn')}
          >
            Leave Chat
          </Button>
          <Button
            variant="primary"
            sx={styles.backToChatButton}
            onClick={closeConfirmLeave}
          >
            Continue with Chat
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
})

export default MayaConfirmLeave
