import React from 'react'
import { Box } from 'rebass'

const ChatButton = ({
  handleClick,
  showChat,
  children,
  fullScreen,
  height
}: any) => {
  const desktopContainerHeight = height ? `calc(${height}px - 100px)` : 'auto'

  return (
    <Box
      sx={
        fullScreen
          ? {
              height: height || '100vh',
              width: '100vw',
              position: 'fixed',
              bottom: 0,
              left: 0,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              bg: 'rgba(0,0,0,0.2)',
              backdropFilter: 'blur(5px)',
              overflow: 'hidden'
            }
          : {
              position: 'fixed',
              cursor: 'pointer',
              bottom: 0,
              right: 0,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              width: ['100%', '100%', '100%', '100%', 560, 560]
            }
      }
    >
      <Box
        sx={{
          width: ['100%', '100%', '100%', '100%', 560, 560],
          height: [
            '100%',
            '100%',
            '100%',
            '100%',
            desktopContainerHeight,
            desktopContainerHeight
          ],
          marginBottom: fullScreen ? '0' : '16px',
          overflow: fullScreen ? 'hidden' : 'unset',
          display: 'flex',
          alignItems: 'flex-end',
          ...(!showChat ? { display: 'none' } : {})
        }}
      >
        {children}
      </Box>

      <Box
        onClick={() => {
          handleClick()
        }}
        sx={{
          display: fullScreen ? 'none' : 'block',
          cursor: 'pointer',
          borderRadius: '100%',
          bg: 'backgroundPrimary',
          p: 4,
          fontSize: 6,
          width: 'fit-content'
        }}
      >
        &#127913;
      </Box>
    </Box>
  )
}

export default ChatButton
